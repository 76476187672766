import React from "react"
import { graphql, Link } from "gatsby"
import tw from "twin.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/free-solid-svg-icons"
import SEO from "../components/SEO"
import { Article, ArticleCollection, SiteMetadata } from "../model/cms"
import { Layout } from "../components/Layout"
import { NewsletterForm } from "../components/NewsletterForm"



const Container = tw.div`flex flex-row`
const Content = tw.div`grid grid-cols-1 lg:grid-cols-3 border-b-2`
const Section = tw.div`px-8 w-full border-t-2 border-l-2 -m-px`
const SectionWide = tw(Section)`lg:col-span-2`
const SectionFull = tw(Section)`col-span-full`
const SectionContainer = tw.div`py-8 h-full w-full`
const TextAccent = tw.span`text-green-400`
const TextAccentSlogan = tw.span`font-bold text-purple-500`
const HeroContainer = tw.div`py-16`
const HeroTitle = tw.h1`text-5xl font-bold font-display`
const HeroSubtitle = tw.h2`text-xl my-8`
const HeroSlogan = tw.h3`text-2xl`
const HeroCallToActionContainer = tw.div`mt-8`
const HeroCallToAction = tw.a`underline hover:text-blue-500`
const SectionTitle = tw.h2`text-2xl font-display`
const TopSectionContainer = tw.ul`py-4`
const TopSectionItem = tw.li`py-2`
const TopSectionText = tw.span`text-2xl font-display`

interface IndexPageProps {
    data: {
        site: {
            siteMetadata: SiteMetadata
        },
        topArticles: ArticleCollection
        articles: ArticleCollection
    }
}

interface TopSectionProps {
    discordUrl: string
    article: Article
}

function TopSection({ discordUrl, article }: TopSectionProps) {
    return (
        <SectionFull>
            <TopSectionContainer>
                <TopSectionItem>
                    <Link to={`/articles/${article.slug}`}>
                        <TopSectionText>
                            📣 {article.title}
                        </TopSectionText>
                    </Link>
                </TopSectionItem>
                <TopSectionItem>
                    <a href={discordUrl}>
                        <TopSectionText>
                            👉 Join here
            </TopSectionText>
                    </a>
                </TopSectionItem>
            </TopSectionContainer>
        </SectionFull>
    )
}

interface HeroProps {
    discordUrl: string
}

function Hero({ discordUrl }: HeroProps) {
    return (
        <SectionWide>
            <HeroContainer>
                <HeroTitle>Moving the <TextAccent>decentralized</TextAccent> ecosystem forward</HeroTitle>
                <HeroSubtitle>
                    We support early-stage projects to conquer challenges. Get started on the right foot by taking the right
                    technology decisions with help from our amazing community.
        </HeroSubtitle>
                <HeroSlogan>
                    <TextAccentSlogan>Faster</TextAccentSlogan> delivery, <TextAccentSlogan>Better</TextAccentSlogan> product, <TextAccentSlogan>Stronger</TextAccentSlogan> results.
        </HeroSlogan>
                <HeroCallToActionContainer>
                    <HeroCallToAction href={discordUrl}>Join our community</HeroCallToAction>
                </HeroCallToActionContainer>
            </HeroContainer>
        </SectionWide>
    )
}

interface NewsSectionProps {
    articles: Article[]
}

function NewsSection({ articles }: NewsSectionProps) {
    const NewsItem = tw.li`pt-4`
    const LinkGroup = tw.span`group-hover:underline`
    return (
        <Section>
            <SectionContainer>
                <SectionTitle>Highlights</SectionTitle>
                <ul>
                    {articles.map(article => (
                        <NewsItem key={article.slug}>
                            <Link className="group" to={`/articles/${article.slug}`}>
                                <FontAwesomeIcon icon={faPlus} /> <LinkGroup>{article.title}</LinkGroup>
                            </Link>
                        </NewsItem>
                    ))}
                </ul>
            </SectionContainer>
        </Section>
    )
}

function LabsPickSection() {
    const Box = tw.div`pt-4`
    const BoxTitle = tw.span`font-semibold mr-2`

    const ApplyLink = tw(Link)`underline hover:text-blue-500 mt-4`

    return (
        <SectionWide>
            <SectionContainer>
                <SectionTitle>Labs' Pick</SectionTitle>
                <Box>
                    <BoxTitle>
                        😄 Everyone's welcome.
          </BoxTitle>
          We welcome projects with diverse background. Apply with just an idea or with a prototype already built.
        </Box>
                <Box>
                    <BoxTitle>
                        ❤️ Community.
          </BoxTitle>
          Work closely with brilliant makers and hackers. we encourage all Lab's Picks to present in front of their
          peers,
          get early-stage feedback from our community.
        </Box>
                <Box>
                    <BoxTitle>
                        ⚗️ Tech advisor.
          </BoxTitle>
          We know how important it is to take the right technical decisions at the beginning of a project. From idea to
          delivery, we provide expert advice to make sure you overcome all technical challenges.
        </Box>
                <Box>
                    <BoxTitle>
                        🕺 No fees, no equity.
          </BoxTitle>
          The program is non-profit, with the only objective of advancing the decentralized ecosystem. For this reason,
          only the most promising projects become Labs' Picks.
        </Box>
                <ApplyLink to="/join">Apply to become a Pick</ApplyLink>
            </SectionContainer>
        </SectionWide>
    )
}

function NewsletterSection() {
    const OuterBox = tw.div`flex items-center justify-center h-full w-full`
    const Box = tw.div`font-display text-2xl`
    return (
        <Section>
            <SectionContainer>
                <OuterBox>
                    <Box>
                        <p>Join the newsletter to receive our articles before everyone else</p>
                        <NewsletterForm />
                    </Box>
                </OuterBox>
            </SectionContainer>
        </Section>
    )
}

export default function IndexPage({ data }: IndexPageProps) {
    const { discordUrl } = data.site.siteMetadata
    const articles = data.articles.edges.map(edge => edge.node)

    const topArticle = data.topArticles.edges[0].node

    return (
        <Layout>
            <SEO
                title="Long Rock Labs"
                description="Long Rock Labs community helps you deliver your blockchain project faster."
                meta={[]}
            />
            <Container>
                <Content>
                    <TopSection article={topArticle} discordUrl={discordUrl} />
                    <Hero discordUrl={discordUrl} />
                    <NewsSection articles={articles} />
                    <LabsPickSection />
                    <NewsletterSection />
                </Content>
            </Container>
        </Layout>
    )
}

export const pageQuery = graphql`
    {
        site {
            siteMetadata {
                discordUrl
                twitterUrl
            }
        }
	topArticles: allGraphCmsArticle(
	  filter: {topArticle: {eq: true}}
	  limit: 1
	  sort: {fields: createdAt, order: DESC}
	) {
	  edges {
	    node {
	      slug
	      title
	    }
	  }
	}
	articles: allGraphCmsArticle(sort: {fields: createdAt, order: DESC}, limit: 5) {
	  edges {
	    node {
	      slug
	      title
	    }
	  }
	}
    }
`
